import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../context/routesContext';
import getLocalization from '../../util/localization';
import translateObj from '../../util/translateObj';
import imageModifier from './helpers/imageModifier';

class MediaDescription extends PureComponent {
  render() {
    const {
      imageUrl,
      description,
      match,
      classes,
      imgClass,
      isDisplayDescriptionOn,
      layout,
      parentComponent,
    } = this.props;

    const showDescription = isDisplayDescriptionOn && description.hasOwnProperty('en') && description.en.length > 0;

    const textStyles = () => {
      switch (parentComponent) {
        case 'StandardArticlePage': return 'mt-4 mb-2 laptop:mb-8 text-right font-stratos text-14 text-grey font-normal';
        case 'ListsPostPage': return 'text-right col-span-3 col-end-7 tablet:col-span-8 laptop:col-span-6 tablet:col-end-13 laptop:col-end-12 font-stratos text-10 laptop:text-sm desktop:text-base text-grey mt-2 tablet:mt-14px laptop:mt-2 desktop:mt-4';
        case 'NewsPostPage': return 'mt-4 text-right font-stratos text-base text-grey font-normal text-11 laptop:text-base';
        case 'CoverStoryPostPage': return 'mt-4 text-left font-stratos text-base text-grey font-normal text-11 laptop:text-base col-start-2 col-span-11';
        case 'RecordsPostPage': return 'mt-4 mb-8 laptop:mb-15 text-left font-stratos text-base text-grey font-normal text-11 laptop:text-base leading-20';
        default: return 'mt-4 mb-4 laptop:mb-15 text-left font-stratos text-base text-grey font-normal text-11 laptop:text-base';
      }
    };

    const containerStyle = () => {
      switch (parentComponent) {
        case 'RecordsPostPage': return 'mt-7';
        default: return 'mt-10';
      }
    };

    const headerDescriptionContainerStyle = () => {
      switch (parentComponent) {
        case 'CoverStoryPostPage': return `${layout === 'head_alternative' ? 'mr-4 tablet:mr-11' : 'mr-4'} laptop:mr-0 max-w-2/3 laptop:max-w-full laptop:col-start-9 laptop:col-end-12`;
        default: return `${layout === 'head_alternative' ? 'mr-4 tablet:mr-11' : 'mr-4'} laptop:mr-0 max-w-2/3 laptop:max-w-full laptop:col-start-5 laptop:col-end-12`;
      }
    };

    const renderHeaderDescription = localization => (
      <div className={isDisplayDescriptionOn === false ? 'hidden' : `absolute pin-r ${headerDescriptionContainerStyle()} desktop:mx-auto desktop:max-w-rdl-lg`}>
        {showDescription && (
          <div className="mt-4 text-grey text-right font-stratos text-12 tablet:text-14 innerTextPadding hasLinksAndBolds">
            {parse(translateObj(description, localization.language))}
          </div>
        )}
      </div>
    );

    const renderNormalDescription = localization => (
      <div className={`w-full desktop:mx-auto desktop:max-w-rdl-lg ${showDescription ? `${parentComponent === 'NewsPostPage' ? 'pb-10' : 'laptop:pb-6'}` : 'mb-10'}`}>
        <div className={`${textStyles()} innerTextPadding hasLinksAndBolds`}>
          {showDescription
            && parse(translateObj(description, localization.language))
          }
        </div>
      </div>
    );

    const renderDescription = (localization) => {
      switch (layout) {
        case 'head':
          return (
            <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4 px-6 tablet:px-11 laptop:px-90px relative">
              {renderHeaderDescription(localization)}
            </div>
          );
        case 'none':
          if (parentComponent === 'NewsPostPage') {
            return (
              <div className="ml-4 tablet:ml-11 laptop:ml-0">
                {renderNormalDescription(localization)}
              </div>
            );
          }
          return (
            <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4 px-6 tablet:px-11 laptop:px-90px">
              <div className="col-start-1 col-end-7 tablet:col-end-13 laptop:col-start-2 laptop:col-end-12">
                {renderNormalDescription(localization)}
              </div>
            </div>
          );
        default:
          return (
            <div className="ml-4 tablet:ml-11 laptop:ml-0">
              {renderNormalDescription(localization)}
            </div>
          );
      }
    };


    if (layout === 'head_alternative') {
      return (
        <RoutesContext.Consumer>
          {({ language: contextLanguage, enteredFrom }) => {
            const localization = getLocalization(contextLanguage, enteredFrom, match);
            return (
              <div className="grid grid-cols-6 tablet:grid-cols-12 gap-3 laptop:gap-4 px-4 tablet:px-11 laptop:px-90px mt-16 tablet:mt-20 laptop:mt-36 desktop:mt-40 relative">
                <div className="col-start-1 col-end-7 tablet:col-end-13 laptop:col-start-2 laptop:col-end-12 text-center">
                  {imageUrl && (
                    <img
                      className={`w-full block ${imgClass}`}
                      src={imageModifier(translateObj(imageUrl, localization.language), '1400x0')}
                      alt={
                        translateObj(description, localization.language)
                        || translateObj(imageUrl, localization.language)
                      }
                    />
                  )}
                  {' '}
                  {renderHeaderDescription(localization)}
                </div>
              </div>
            );
          }}
        </RoutesContext.Consumer>
      );
    }

    return (
      <RoutesContext.Consumer>
        {({ language: contextLanguage, enteredFrom }) => {
          const localization = getLocalization(contextLanguage, enteredFrom, match);
          return (
            <div className={`${classes || ''} relative w-full ${layout !== 'head' ? containerStyle() : ''}`}>
              <div className="text-center">
                {imageUrl && (
                  <img
                    className={`w-full block ${imgClass}`}
                    src={imageModifier(translateObj(imageUrl, localization.language), '1400x0')}
                    alt={translateObj(description, localization.language) || translateObj(imageUrl, localization.language)}
                  />
                )}
                {' '}
                {renderDescription(localization)}
              </div>
            </div>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
}
MediaDescription.propTypes = {
  description: PropTypes.shape({}),
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
  classes: PropTypes.string,
  imgClass: PropTypes.string,
  isDisplayDescriptionOn: PropTypes.bool,
  layout: PropTypes.string,
  parentComponent: PropTypes.string,
};

MediaDescription.defaultProps = {
  description: null,
  classes: '',
  imgClass: '',
  isDisplayDescriptionOn: true,
  layout: '',
  parentComponent: '',
};

export default withRouter(MediaDescription);
