import gql from 'graphql-tag';

import { clientWeb } from '../graphqlAPI';

const PROFILE_USER_EDIT = gql`
  mutation Post(
    $email: String!,
    $name: String,
    $lastname: String,
    $nickname: String,
    $postalCode: String,
    $dateOfBirth: String,
    $inputCountry: String,
    $acceptedConditions: Boolean,
    $description: String,
    $occupation: String,
    $company: String,
    $url: String,
    $imageUrl: String,
    $social: [SocialItemInput],
    $userType: String,
    $isPrivate: Boolean,
    $language: String,
    $business: String,
    $city: String,
    $addressName: String,
    $addressLastname: String,
    $addressStreet: String,
    $addressStreetNumber: String,
    $addressApartment: String,
    $addressCity: String,
    $addressProvince: String,
    $addressPostalCode: String,
    $addressCountry: String,
    $acceptedConditionsMusicMeets: Boolean,
    $idNumber: String,
  ) {
    profileUserEdit(
      email: $email,
      name: $name,
      lastname: $lastname,
      nickname: $nickname,
      postalCode: $postalCode,
      dateOfBirth: $dateOfBirth,
      inputCountry: $inputCountry,
      acceptedConditions: $acceptedConditions
      description: $description
      occupation: $occupation
      company: $company
      url: $url
      imageUrl: $imageUrl
      social: $social
      userType: $userType
      isPrivate: $isPrivate
      language: $language
      city: $city,
      business: $business,
      addressName: $addressName ,
      addressLastname: $addressLastname,
      addressStreet: $addressStreet,
      addressStreetNumber: $addressStreetNumber,
      addressApartment: $addressApartment,
      addressCity: $addressCity,
      addressProvince: $addressProvince,
      addressPostalCode: $addressPostalCode,
      addressCountry: $addressCountry,
      acceptedConditionsMusicMeets: $acceptedConditionsMusicMeets
      idNumber: $idNumber,
      ){
        isAuth
        isEdited
        token
        error
    }
}`;

const profileUserEdit = async (formData) => {
  try {
    const result = await clientWeb.mutate({
      mutation: PROFILE_USER_EDIT,
      variables: {
        ...formData,
        fetchDate: new Date().getTime().toString(),
      },
      fetchPolicy: 'no-cache',
    })
      .then(queryResult => queryResult)
      .catch((queryError) => {
        console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: Apollo Client Query Error: ${queryError}`);
        return null;
      });

    const { data: { profileUserEdit: validatedFormData } } = result;

    if (validatedFormData) {
      return validatedFormData;
    }
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: GraphQL Query Error: validatedFormData = null`);
    return null;
  } catch (error) {
    console.log('\x1b[31m%s\x1b[0m', `${new Date().toLocaleTimeString()} ~ Client: profileUserEdit Error: ${error}`);
    return null;
  }
};

export default profileUserEdit;
