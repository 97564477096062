const dictionaryCategories = {
  articulos: 'Artículos',
  revision: 'Revisión',
  blank: 'Blank',
  discos: 'Discos',
  cultura: 'Cultura',
  cine: 'Cine',
  comix: 'Cómics',
  comics: 'Cómics',
  dvd: 'DVD',
  libros: 'Libros',
  tv: 'Series',
  albumes: 'Álbumes',
  reediciones: 'Reediciones',
  singles: 'Singles',
  media: 'Media',
  clips: 'Clips',
  estrenos: 'Estrenos',
  radio: 'Pódcast',
  videoentrevistas: 'Vide Entrevistas',
  musica: 'Música',
  entrevistas: 'Entrevistas',
  live: 'Live',
  noticias: 'Noticias',
  opinion: 'Opinión',
  edit: 'Edit',
  freestyle: 'Freestyle',
  'visto-y-no-visto': 'Visto y no visto',
  'firma-invitada': 'Firma invitada',
  revista: 'Revista',
  artista: 'Artísta',
  system: 'system',
  dancedelux: 'dl',
  rockdelux: 'rdl',
  factory: 'Factory',
  '00-home': 'Home',
  random: 'Random',
  autor: 'Autor',
  'en-portada': 'En portada',
  '1-albumes-internacionales': '#1 Álbumes Internacionales',
  '1-albumes-nacionales': '#1 Álbumes Nacionales',
  PLAYLISTS: 'Playlists',
  'textos-legales': 'Textos Legales',
  actualidad: 'Actualidad',
  lifestyle: 'Lifestyle',
  '1-reediciones': '#1 Reediciones',
  listas: 'Listas',
  'listas-musica': 'Listas de música',
  'listas-cine': 'Listas de cine',
  'listas-series': 'Listas de series',
  'listas-libros': 'Listas de libros',
  'listas-de-gaming': 'Listas de gaming',
  'listas-comics': 'Listas de cómics',
  'listas-cultura': 'Listas de cultura',
  viral: 'Viral',
  series: 'Series',
  tecnologia: 'Tecnología',
  deportes: 'Deportes',
  radar: 'Radar',
  gastronomia: 'gastronomía',
  'visto-no-visto': 'Visto / No visto',
  'powered-by-ikea': 'Powered by Ikea',
  'magazine-page': 'Hemeroteca',
};

export default dictionaryCategories;
