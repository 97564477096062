import React, { useEffect } from 'react';
import { useBraze } from '../../../context/useBraze';

const ButtonLinkWithBadge = () => {
  const { braze, numberOfContentCards } = useBraze();

  const handleNotification = () => {
    if (!braze) return;
    braze.showContentCards();
    braze.logCustomEvent('clicks-on-card-menu');
  };

  const handleMutation = (event) => {
    const { target, type } = event;
    if (type === 'DOMNodeRemoved' && target.classList.contains('ab-feed')) {
      braze.requestContentCardsRefresh();
    }
  };

  useEffect(() => {
    document.addEventListener('DOMNodeInserted', handleMutation);
    document.addEventListener('DOMNodeRemoved', handleMutation);

    return () => {
      document.removeEventListener('DOMNodeInserted', handleMutation);
      document.removeEventListener('DOMNodeRemoved', handleMutation);
    };
  }, []);

  if (numberOfContentCards === 0) return null;

  return (
    <button type="button" className="flex items-center justify-center xs:justify-start h-8 -mb-px uppercase font-stratos" onClick={handleNotification}>
      <div className="relative w-4 h-4 bg-red rounded-full" style={{ top: '-10px', right: '-9px' }}>
        <span className="flex pt-2px text-white justify-center items-center text-11px font-normal">
          {numberOfContentCards}
        </span>
      </div>
      <span className="font-newicons icon-new-notification text-black text-18 tablet:text-20 laptop:text-30" />
    </button>
  );
};

export default ButtonLinkWithBadge;
