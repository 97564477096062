import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import ComponentIterator from '../../../containers/ComponentIterator';
import imageModifier from '../helpers/imageModifier';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import PSResponsiveUtility from '../LineUp/PSResponsiveUtility';
import getPostsBySlugNameQuery from '../../../api/graphql/queries/getPostsBySlugNameQuery';

const SliderBannerHome = ({
  image, left, right, match, single, areSlidesShort, notSlider, showOpacity,
}) => {
  const [mobileArr, setMobileArr] = useState([...left, ...right]);
  const [deviceWidth, setDeviceWidth] = useState('lg');
  const [extraMargin, setExtraMargin] = useState();
  const [extraPadding, setExtraPadding] = useState(true);
  const [overlayImage, setOverlayImage] = useState(null);

  const fetchPostsBySlugNames = (slugnames) => {
    getPostsBySlugNameQuery(slugnames)
      .then((result) => {
        if (result) {
          setOverlayImage(result[0].postDescription.overlayImage);
        }
      })
      .catch((err) => {
        console.log('Some error happened on fetch of fetchPostDescription:', err);
      });
  };

  const responsiveUtility = useRef();
  const setMargin = (x) => {
    x.map(component => Object.assign(component, { slider: true }));
  };
  const getWidth = () => {
    // actions when window is resized
    setDeviceWidth(responsiveUtility.current.deviceScreen(true));
  };
  useEffect(() => {
    const linkButton = mobileArr.find(el => el.type === 'LinkButtonRounded');
    if (linkButton) {
      const arr = mobileArr;
      arr.push(arr.splice(arr.indexOf(linkButton), 1)[0]);
      setMobileArr(arr);
    }
    getWidth();
  }, []);

  useEffect(() => {
    mobileArr.forEach((item, index) => {
      // Ensure slider prop
      if (typeof item.slider === 'undefined') {
        Object.assign(item, { slider: true });
      }
      if (item.type === 'PretitleLineH2') {
        setExtraPadding(false);
      }
      if (
        (index === (mobileArr.length - 1)) && (item.type !== 'LinkButtonRounded')) {
        setExtraMargin(true);
      } else {
        setExtraMargin(false);
      }
    });
  }, []);

  useEffect(() => {
    if (left) {
      left.forEach((item) => {
        if (item.type === 'LinkButtonRounded') {
          const urlParts = item.url.split('/');
          const slugName = urlParts[urlParts.length - 1];
          if (slugName) {
            fetchPostsBySlugNames(slugName);
          }
        }
      });
    }
  }, []);

  return (
    <RoutesContext.Consumer>
      {({ language: contextLanguage, enteredFrom }) => {
        const localization = getLocalization(contextLanguage, enteredFrom, match);
        let imageurl = `url(${imageModifier(translateObj(image, localization.language), '2880x0')})`;
        if (deviceWidth === 'sm') imageurl = `url(${imageModifier(translateObj(image, localization.language), '828x0')})`;
        if (deviceWidth === 'md') imageurl = `url(${imageModifier(translateObj(image, localization.language), '1536x0')})`;
        const backgroundStyle = {
          backgroundImage: imageurl,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        };

        if (notSlider) {
          return (
            <>
              <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} />
              <div
                key={image.en}
                className={`${!areSlidesShort ? 'desktop:h-screen90 laptop:h-screen80 tablet:h-screen65 h-screen65' : 'h-screen40 md:h-carouselMD sm:h-carouselSM'} ${showOpacity ? 'sliderComponent' : ''} w-full relative flex flex-col justify-end overflow-hidden min-h-screen40`}
              >
                <div
                  key={image.en}
                  className={`${!areSlidesShort ? 'desktop:h-screen90 laptop:h-screen80 tablet:h-screen65 h-screen65' : 'h-screen40 md:h-carouselMD sm:h-carouselSM'} w-full absolute pin-l pin-b`}
                  style={backgroundStyle}
                />
                <div className="text-white flex items-end h-full relative" style={{ zIndex: 2 }}>
                  <div className="px-6 tablet:px-11 laptop:px-90px pb-6 tablet:pb-11 laptop:pb-12 w-full h-full flex justify-start sm:flex-col sm:justify-end items-end">
                    <div className="w-full h-full flex flex-col justify-end mr-12 sm:mr-0">
                      <div data-name="slider-wrapper" className="flex h-full flex-col items-stretch justify-end">
                        <ComponentIterator
                          components={mobileArr}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }


        return (
          <>
            <PSResponsiveUtility ref={responsiveUtility} deviceWidthCallback={getWidth} />
            <div
              key={image.en}
              className={`${!areSlidesShort ? 'desktop:h-screen90 laptop:h-screen80 tablet:h-screen65 h-screen65' : 'h-screen40 md:h-carouselMD sm:h-carouselSM'} ${showOpacity ? 'sliderComponent' : ''} w-full relative flex flex-col justify-end overflow-hidden min-h-screen40`}
            >
              <div
                key={image.en}
                className={`${!areSlidesShort ? 'desktop:h-screen90 laptop:h-screen80 tablet:h-screen65 h-screen65' : 'h-screen40 md:h-carouselMD sm:h-carouselSM'} w-full absolute pin-l pin-b`}
                style={backgroundStyle}
              />
              <div className="text-white flex items-end h-full relative" style={{ zIndex: 2 }}>
                <div className="tablet:px-70px laptop:px-24 laptop:pb-26px px-5 pb-5 tablet:pb-3 w-full h-full flex justify-start sm:flex-col sm:justify-end items-end">
                  {overlayImage && (
                  <div className="absolute pin-t pin-r mt-24 mr-7">
                    <img src={imageModifier(overlayImage, '90x90')} alt="Premium content" />
                  </div>
                  )}
                  {deviceWidth !== 'sm' ? (
                    <div className="w-full h-full flex flex-col justify-end mr-12 sm:mr-0">
                      <div data-name="slider-wrapper" className="flex h-full flex-col items-stretch justify-start max-h-sliderContentMobile tablet:max-h-sliderContentTablet laptop:max-h-sliderContentLaptop desktop:max-w-sliderContentDesktop">
                        {extraPadding && <div className="pt-4 laptop:pt-5" />}
                        <ComponentIterator
                          components={mobileArr}
                          {...setMargin(left)}
                        />
                      </div>
                    </div>
                  )
                    : (
                      <div className="w-full h-full max-h-sliderContentMobile tablet:max-h-sliderContentTablet laptop:max-h-sliderContentLaptop desktop:max-w-sliderContentDesktop">
                        <div className={`h-full ${extraMargin ? 'mb-8 tablet:mb-0' : ''}`}>
                          <div className="flex h-full flex-col items-stretch justify-start">
                            {extraPadding && <div className="pt-4 laptop:pt-5" />}
                            <ComponentIterator components={mobileArr} />
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        );
      }
      }
    </RoutesContext.Consumer>
  );
};

SliderBannerHome.propTypes = {
  image: PropTypes.shape({}),
  right: PropTypes.arrayOf(PropTypes.shape()),
  left: PropTypes.arrayOf(PropTypes.shape()),
  single: PropTypes.bool,
  areSlidesShort: PropTypes.bool,
  match: PropTypes.shape().isRequired,
  notSlider: PropTypes.bool,
  showOpacity: PropTypes.bool,
};

SliderBannerHome.defaultProps = {
  right: null,
  left: null,
  image: {},
  single: true,
  areSlidesShort: false,
  notSlider: false,
  showOpacity: true,
};

export default withRouter(SliderBannerHome);
