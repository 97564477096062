import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import RoutesContext from '../../../context/routesContext';
import getLocalization from '../../../util/localization';
import translateObj from '../../../util/translateObj';
import Container from '../Container';

const TitleLineH1 = ({
  text, align, color, margin, match, slider, parentComponent, isHeadTitle, bgColor, textAlign,
}) => (
  <RoutesContext.Consumer>
    {({ language: contextLanguage, enteredFrom }) => {
      const localization = getLocalization(contextLanguage, enteredFrom, match);
      const textColorTailwind = color && !color.includes('#');
      const textColorHex = color && color.includes('#');

      const textSize = () => {
        let textStyle = 'pb-8 font-bold text-32 laptop:text-42 full-width tablet:mx-2px laptop:mx-0 laptop:pb-10';
        switch (parentComponent) {
          case 'ListsPostPage':
            textStyle = 'col-span-6 mt-2 font-bold text-black leading-52 tablet:col-span-12 text-46 tablet:text-56 laptop:text-78 desktop:text-86 desktop:leading-98 laptop:col-start-2 laptop:col-span-10 laptop:mt-5 desktop:mt-0 font-stratos tracking-1.7px';
            break;
          case 'RecordsPostPage':
            textStyle = 'font-semibold leading-52 tablet:leading-64 text-46 tablet:text-56 laptop:text-78 desktop:text-86 tracking-mid tablet:tracking-small desktop:tracking-mid laptop:leading-92 desktop:leading-98';
            break;
          case 'Paywall':
            textStyle = 'font-bold text-36 font-stratos';
            break;
          case 'StandardArticlePage':
            textStyle = 'mt-8 font-semibold leading-52 text-46 tablet:text-56 laptop:text-78 desktop:text-86 desktop:leading-98 font-stratos tracking-1.7px';
            break;
          case 'CoverStoryPostPage':
            textStyle = 'font-light font-merriw text-46 tablet:text-58 laptop:text-82 desktop:mt-3 desktop:text-92 desktop:leading-112';
            break;
          case 'NewsPostPage':
            textStyle = 'font-bold text-46 tablet:text-56 tracking-1.7px font-stratos leading-tight';
            break;
          default:
            textStyle = 'pb-8 font-bold text-32 laptop:text-42 full-width tablet:mx-2px laptop:mx-0 laptop:pb-10';
            break;
        }
        return textStyle;
      };
      const paddings = () => {
        let paddingStyle = 'px-5 tablet:px-10 laptop:px-24';
        switch (parentComponent) {
          case 'ListsPostPage':
            paddingStyle = 'laptop:pb-0 desktop:pb-3';
            break;
          case 'RecordsPostPage':
            paddingStyle = 'pb-1 tablet:pb-0';
            break;
          case 'Paywall':
            paddingStyle = 'pb-2 laptop:pb-3';
            break;
          case 'NewsPostPage':
            paddingStyle = '';
            break;
          case 'StandardArticlePage':
            paddingStyle = 'pl-0';
            break;
          case 'CoverStoryPostPage':
            paddingStyle = 'px-0';
            break;
          default:
            paddingStyle = 'px-5 tablet:px-10 laptop:px-24';
            break;
        }
        return paddingStyle;
      };

      if (isHeadTitle) {
        return (
          <div style={{ backgroundColor: bgColor }} className="w-full mt-15 tablet:mt-18 laptop:mt-29 desktop:mt-30">
            <Container>
              <h1
                className={`${textColorTailwind ? `text-${color}` : ''} text-32 tablet:text-52 laptop:text-62 desktop:text-82 font-semibold font-stratos tracking-small pb-22px tablet:pb-6 pt-9 tablet:pt-12 laptop:pb-5 desktop:pb-8 laptop:pt-15 desktop:pt-24`}
                style={textColorHex ? { color } : null}
              >
                {translateObj(text, localization.language)}
              </h1>
            </Container>
          </div>
        );
      }

      return (
        <h1
          style={{ color: textColorHex ? color : 'auto', textAlign: textAlign === false ? 'inherit' : textAlign }}
          className={`
          font-stratos w-full ${align} ${margin} ${textColorTailwind && `text-${color}`}
          ${slider ? 'text-38 sm:leading-36 tablet:text-36 laptop:text-72 desktop:text-68 font-bold laptop:font-semibold pb-2px tablet:pb-3 laptop:pb-3' : `${paddings()} ${textSize()}`}`}
        >
          {translateObj(text, localization.language)}
        </h1>
      );
    }}
  </RoutesContext.Consumer>
);

export default withRouter(TitleLineH1);

TitleLineH1.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
  margin: PropTypes.string,
  text: PropTypes.shape({
    en: PropTypes.string,
    es: PropTypes.string,
    ca: PropTypes.string,
  }),
  match: PropTypes.shape().isRequired,
  slider: PropTypes.bool,
  parentComponent: PropTypes.string,
  isHeadTitle: PropTypes.bool,
  bgColor: PropTypes.string,
  textAlign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

TitleLineH1.defaultProps = {
  margin: '',
  color: 'black',
  align: 'align-left',
  slider: false,
  parentComponent: null,
  isHeadTitle: false,
  bgColor: 'transparent',
  textAlign: 'left',
  text: {
    en: '',
    es: '',
    ca: '',
  },
};
